import React from "react";
import { FaFacebook, FaTiktok } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";

const Footer = () => {
  return (
    <div className="py-5 mt-3  bg-amber-50 flex flex-wrap justify-center items-center gap-2 md:gap-10  absolute right-0 left-0 ">
      <p>A Töffgleraugu Product © 2024 - All Rights Reserved</p>
      <p className="flex gap-3">
        <a href="https://www.facebook.com/p/T%C3%B6ff-gleraugu-fyrir-t%C3%B6ff-konur-100066860737674/?locale=es_ES&paipv=0&eav=AfapBZ2Wgq65tPItiBTwRIsTIHdWKHrg0xYyfsrik1URtO9qCJGTGPMJ-XUybejOMhA&_rdr">
        <FaFacebook className="text-2xl text-gray-800" />
        </a>
        <a href="https://www.facebook.com/p/T%C3%B6ff-gleraugu-fyrir-t%C3%B6ff-konur-100066860737674/?locale=es_ES&paipv=0&eav=AfapBZ2Wgq65tPItiBTwRIsTIHdWKHrg0xYyfsrik1URtO9qCJGTGPMJ-XUybejOMhA&_rdr">
          {" "}
          <IoLogoInstagram className="text-2xl text-gray-800" />
        </a>
        <a href="https://www.facebook.com/p/T%C3%B6ff-gleraugu-fyrir-t%C3%B6ff-konur-100066860737674/?locale=es_ES&paipv=0&eav=AfapBZ2Wgq65tPItiBTwRIsTIHdWKHrg0xYyfsrik1URtO9qCJGTGPMJ-XUybejOMhA&_rdr">
        <FaTiktok className="text-2xl text-gray-800" />
        </a>
      </p>
    </div>
  );
};

export default Footer;
