import sun3 from "../../assets/sunglasses/sun3.png";
import sun4 from "../../assets/sunglasses/sun4.png";
import sun5 from "../../assets/sunglasses/sun5.png";
import sun7 from "../../assets/sunglasses/sun7.png";
import sun8 from "../../assets/sunglasses/sun8.png";
import sun9 from "../../assets/sunglasses/sun9.png";
import sun10 from "../../assets/sunglasses/sun10.png";
import sun11 from "../../assets/sunglasses/sun11.png";
import sun12 from "../../assets/sunglasses/sun12.png";
import sun13 from "../../assets/sunglasses/sun13.png";
import sun14 from "../../assets/sunglasses/sun14.png";

import sports1 from "../../assets/sports/sports1.png";
import sports2 from "../../assets/sports/sports2.png";
import sports3 from "../../assets/sports/sports3.png";
import sports4 from "../../assets/sports/sports4.png";
import sports6 from "../../assets/sports/sports6.png";
import sports7 from "../../assets/sports/sports7.png";
import sports8 from "../../assets/sports/sports8.png";
import sports9 from "../../assets/sports/sports9.png";

import vision1 from "../../assets/vision/vision1.png";
import vision2 from "../../assets/vision/vision2.png";
import vision3 from "../../assets/vision/vision3.png";
import vision4 from "../../assets/vision/vision4.png";
import vision5 from "../../assets/vision/vision5.png";
import vision6 from "../../assets/vision/vision6.png";
import vision7 from "../../assets/vision/vision7.png";
import vision8 from "../../assets/vision/vision8.png";
import vision9 from "../../assets/vision/vision9.png";

export const products = [
  {
    _id: "3d7e9c33-0080-4bd2-b2bf-56552da31e35",
    qty: 0,
    name: "Ísafold Square",
    description: "Fallegt torglaga gleraugu með klassísku sniði og einstaka andstæðum linsuföllum fyrir þær sem vilja skýra útsýnið. Ísafold Square er fullkomin fyrir daglega notkun og áhugavert að sjá.",
    brand: "Icelandic Eyewear Co.",
    category: "Fashion",
    gender: "Women",
    weight: "200g",
    quantity: 10,
    image: sports1,
    rating: 4.5,
    price: 11999,
    newPrice: 11899,
    trending: true,
  },
  {
    _id: "887cdd99-1503-4527-aef4-88c521126a47",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "100g",
    quantity: 5,
    image: sports2,
    rating: 4.8,
    price: 1299,
    newPrice: 1199,
    trending: true,
  },
  {
    _id: "dc2b2495-eb6c-4889-a6f7-3c8853a01ba2",
    qty: 0,
    name: "Vetrarrosir Round",
    description: "Glæsileg, hringlaga gleraugu sem eru innrétting til að fá fram sólarhringinn á himninum. Vetrarrosir Round eru frábær valkostur fyrir þær sem vilja skjóta skot í stílinn.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "750g",
    quantity: 15,
    image: sports3,
    rating: 4.7,
    price: 2999,
    newPrice: 2000,
    trending: true,
  },
  {
    _id: "fd25068a-9647-45ba-b44d-c8af1523148a",
    qty: 0,
    name: "Vetrarrosir Round",
    description: "Glæsileg, hringlaga gleraugu sem eru innrétting til að fá fram sólarhringinn á himninum. Vetrarrosir Round eru frábær valkostur fyrir þær sem vilja skjóta skot í stílinn.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "300g",
    quantity: 8,
    image: sports4,
    rating: 3.8,
    price: 2499,
    newPrice: 2300,
  },
  {
    _id: "95cd381c-32cf-46e5-8a75-05d30a421d6b",
    qty: 0,
    name: "Vetrarrosir Round",
    description: "Glæsileg, hringlaga gleraugu sem eru innrétting til að fá fram sólarhringinn á himninum. Vetrarrosir Round eru frábær valkostur fyrir þær sem vilja skjóta skot í stílinn.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "900g",
    quantity: 3,
    image: sports6,
    rating: 3.5,
    price: 3999,
    newPrice: 3500,
  },
  {
    _id: "6033723f-a8db-4ad8-bfda-d191778141aa",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "120g",
    quantity: 9,
    image: vision7,
    rating: 4.3,
    price: 2999,
    newPrice: 2599,
  },
  {
    _id: "ac072970-8dac-42fe-8c31-a8fa6fd4b64a",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "550g",
    quantity: 7,
    image: vision8,
    rating: 4.8,
    price: 4999,
    newPrice: 3999,
  },
  {
    _id: "8ad38385-1211-4192-8108-db851b4e9434",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    gender: "Unisex",
    weight: "550g",
    quantity: 7,
    image: vision9,
    rating: 4.8,
    price: 4999,
    newPrice: 4300,
  },
  {
    _id: "c6dcde20-2e9f-470c-b76c-38b01ab7e16e",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "200g",
    quantity: 10,
    image: sun3,
    rating: 4.5,
    price: 1999,
    newPrice: 1899,
  },
  {
    _id: "c76b3840-47d2-4d38-b465-af68e2af9dcd",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "100g",
    quantity: 5,
    image: sun4,
    rating: 2.8,
    price: 1299,
    newPrice: 1200,
  },
  {
    _id: "61bf1558-94c3-4494-a522-0aad46ed5334",
    qty: 0,
    name: "Skjöldur Cat-Eye",
    description: "Draumkenndar kattarauguskrúðgleraugu sem ljúka útlit þínu. Með sniði sem hefur örlítil buxur og silfur aukahluti, eru Skjöldur Cat-Eye frábær valkostur fyrir þær sem vilja einstaklega útlit.",
    brand: "Nordic Designs",
    category: "Fashion",
    gender: "Women",
    weight: "750g",
    quantity: 15,
    image: sun5,
    rating: 4.7,
    price: 2999,
    newPrice: 2399,
    trending: true,
  },
  
];
