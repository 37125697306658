import { Routes, Route, Outlet } from "react-router-dom";
import SharedLayout from "./SharedLayout";
import { contentRoutes } from "./publicRoutes";
import { privateRoutes } from "./privateRoutes";
import { ErrorPage, Home } from "../pages";

const Index = () => {
  return (
    <Routes>
      <Route element={<SharedLayout />}>
        <Route path="/" element={<Home />} index />
        <Route path="*" element={<ErrorPage />} />
        {contentRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
        {privateRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
};

export { Index };
